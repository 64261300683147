<template>
  <v-flex xs12 sm12 mt-5>
    <v-card>
      <v-container>
        <v-layout justify-center>
          <v-card-title text-xs-center primary-title>
            <h5 class="headline mb-0">
              <p style="padding-left:5em">
                <br />
                <b>
                  {{ new Date().getFullYear() }} —
                  <strong>Landing Page</strong>
                </b>
              </p>
            </h5>
          </v-card-title>
        </v-layout>
      </v-container>
    </v-card>
  </v-flex>
</template>

<script>
export default {};
</script>
