<template>
  <v-app>
    <Navbar />
    <Parallax />
    <HeaderLine />
    <ImageGrid />
    <Footer />

    <FloatingButton />
  </v-app>
</template>

<script>
import Parallax from "./components/layout/Parallax.vue";
import Navbar from "./components/layout/Navbar";
import HeaderLine from "./components/layout/HeaderLine.vue";
import ImageGrid from "./components/layout/ImageGrid.vue";
import Footer from "./components/layout/Footer.vue";
import FloatingButton from "./components/FloatingButton.vue";

export default {
  name: "App",

  components: {
    Navbar,
    Parallax,
    HeaderLine,
    ImageGrid,
    FloatingButton,
    Footer,
  },

  data: () => ({}),
};
</script>
