<template>
  <div>
    <v-btn fab fixed large bottom right>
      <v-switch v-model="$vuetify.theme.dark"></v-switch>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "floatingbutton",
};
</script>
