<template>
  <div style="margin-top:64px">
    <v-parallax
      src="https://images.unsplash.com/photo-1547093349-65cdba98369a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80s"
    >
      <v-layout align-center column justify-center>
        <h1 class="display-2 font-weight-thin mb-3 darken-2">
          Lorem, ipsum dolor.
        </h1>
        <h4 class="subheading darken-2">
          Lorem ipsum dolor sit amet.
        </h4>
      </v-layout></v-parallax
    >
  </div></template
>

<script>
export default {
  name: "header",
};
</script>

<style>
.v-parallax__content {
  padding: 0 !important;
  background: linear-gradient(45deg, black, transparent) !important;
}
</style>
