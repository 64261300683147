<template>
  <div>
    <v-app-bar elevate-on-scroll fixed>
      <v-toolbar-title style="margin-left: 7.5vh">Landing page</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-items style="margin-right:7.5vh;">
        <v-btn text rounded>Home</v-btn>
        <v-btn text rounded>Services</v-btn>
        <v-btn text rounded>About</v-btn>
        <v-btn text rounded>Contact</v-btn>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "navbar",
};
</script>
