<template>
  <v-flex xs12 sm12>
    <v-card>
      <v-container>
        <v-layout justify-center>
          <v-card-title text-xs-center primary-title>
            <h3 class="headline mb-0">
              <i>
                “Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Facilis, ipsam. Valley Safari”
                <p style="padding-left:5em">
                  <br />
                  <b>― Lorem Ipsum</b>
                </p>
              </i>
            </h3>
          </v-card-title>
        </v-layout>
      </v-container>
    </v-card>
  </v-flex>
</template>
<script>
export default {
  name: "headerline",
};
</script>
